import React, { useEffect, useState } from 'react';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
// import 'survey-core/defaultV2.css';
import 'survey-core/survey-core.css';
// import 'survey-creator-core/survey-creator-core.css';
import { setSurveyJSON } from './InsertSurveyOnHTML';

const SurveyCreatorRenderComponent = (props) => {
    const [creator, setcreator] = useState(null);

    useEffect(() => {
        if (creator) {
            setTimeout(() => {
                let textContent = document.querySelector('.svc-tabbed-menu-item__text').textContent;
                textContent && (document.querySelector('.svc-tabbed-menu-item__text').textContent = 'Editor');
            }, 100);
        }
    }, [creator]);

    useEffect(() => {
        const cssId = 'survey-creator-core-css';
        if (!document.getElementById(cssId)) {
            const link = document.createElement('link');
            link.id = cssId;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = 'https://asset.common.ovrture.com/shared/css/survey-creator-core.min.css';
            document.head.appendChild(link);
        }

        let surveyCreator = new SurveyCreator({
            showLogicTab: false,
            showJSONEditorTab: false,
        });

        if (props?.editorJSON) {
            // props.editorJSON.widthMode = 'responsive';
            surveyCreator.JSON = props.editorJSON;
        } else {
            surveyCreator.JSON = {};
        }

        surveyCreator.onModified.add(() => {
            setSurveyJSON(surveyCreator.JSON);
        });
        // surveyCreator.onActiveTabChanged.add((sender, options) => {
        //     setTimeout(
        //         () =>
        //             surveyCreator.activeTab === 'designer'
        //                 ? setLabelOnRange(surveyCreator, true)
        //                 : setLabelOnRange(surveyCreator, false),
        //         100
        //     );
        // });
        // surveyCreator.onElementAllowOperations.add((sender, options) => {
        //     setAddItemOnMatrix(surveyCreator);
        //     setLabelOnRange(surveyCreator, true);
        // });

        setSurveyJSON(surveyCreator.JSON);
        setcreator(surveyCreator);

        return () => {
            const existingLink = document.getElementById(cssId);
            if (existingLink) {
                existingLink.remove();
            }
        };
    }, []);

    const setLabelOnRange = (surveyCreator, editable) => {
        if (surveyCreator?.JSON?.pages[0]?.elements[0]?.name === 'slider') {
            const sliderInputs = document.querySelectorAll('input[type="range"]');
            sliderInputs?.forEach((sliderInput) => {
                if (sliderInput && !sliderInput?.parentNode?.querySelector('.inputSliderLabel')) {
                    // Create the custom HTML element
                    const customElement = document.createElement('div');
                    customElement.className = 'inputSliderLabel';
                    customElement.innerHTML = `
                    <div class="left"><span role="textbox" class="sv-string-editor" contenteditable="${editable}" spellcheck="false" aria-placeholder="" aria-label="content editable">Awful</span></div>
                    <div class="right"><span role="textbox" class="sv-string-editor" contenteditable="${editable}" spellcheck="false" aria-placeholder="" aria-label="content editable">Amazing</span></div>`;
                    // Insert the custom element below the slider input
                    sliderInput.parentNode.insertBefore(customElement, sliderInput.nextSibling);
                }
            });
        }
    };

    const setAddItemOnMatrix = (surveyCreator) => {
        if (surveyCreator?.JSON?.pages[0]?.elements[0]?.columns?.length > 0) {
            const toolbarElement = document.querySelector('.svc-survey-element-toolbar');
            if (toolbarElement && !toolbarElement.querySelector('.custom-button')) {
                const customButton = document.createElement('button');
                customButton.innerText = 'Add';
                customButton.className =
                    'custom-button sv-action-bar-item sv-action-bar-item--icon sv-dots__item svc-survey-element-toolbar__item';
                customButton.addEventListener('click', () => {
                    let jsonObj = surveyCreator.JSON;
                    if (jsonObj.pages[0].elements[0].columns.length < 6) {
                        jsonObj.pages[0].elements[0].columns.push(
                            'Column ' + (jsonObj.pages[0].elements[0].columns.length + 1)
                        );
                        jsonObj.pages[0].elements[0].rows.push('Row ' + (jsonObj.pages[0].elements[0].rows.length + 1));
                        setSurveyJSON(jsonObj);
                        surveyCreator.JSON = jsonObj;
                        setcreator(surveyCreator);
                    }
                });
                toolbarElement.appendChild(customButton);
            }

            if (toolbarElement && !toolbarElement.querySelector('.custom-button-remove')) {
                const customButton = document.createElement('button');
                customButton.innerText = 'Remove';
                customButton.className =
                    'custom-button-remove sv-action-bar-item sv-action-bar-item--icon sv-dots__item svc-survey-element-toolbar__item';
                customButton.addEventListener('click', () => {
                    let jsonObj = surveyCreator.JSON;
                    if (jsonObj.pages[0].elements[0].columns.length > 2) {
                        if (jsonObj.pages[0].elements[0].columns.length > 0) {
                            jsonObj.pages[0].elements[0].columns.pop();
                        }
                        if (jsonObj.pages[0].elements[0].rows.length > 0) {
                            jsonObj.pages[0].elements[0].rows.pop();
                        }
                    }
                    setSurveyJSON(jsonObj);
                    surveyCreator.JSON = jsonObj;
                    setcreator(surveyCreator);
                });
                toolbarElement.appendChild(customButton);
            }
        }
    };

    return (
        <>
            {creator && (
                <div
                    className={props?.objectType ? props?.objectType + ' creator_height' : 'creator_height'}
                    style={{ marginTop: '100px', height: '100vh' }}>
                    <SurveyCreatorComponent creator={creator} />
                </div>
            )}
        </>
    );
};

export default SurveyCreatorRenderComponent;
